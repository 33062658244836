import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import DrawingPrompt from './DrawingPrompt'

export default function ProjectsPage({ data, location }) {
  const siteTitle = data.site.siteMetadata?.title
  const author = data.site.siteMetadata?.author?.name

  return (
    <Layout location={location} title={siteTitle} author={author}>
      <SEO title="projects" />
      <DrawingPrompt />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        author {
          name
        }
        title
      }
    }
  }
`
